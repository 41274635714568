// en.js
export default {
    main: {
        视频教程: "Video Tutorials",
        介绍: "Introduction",
        联系我们: "Contact",
        快速学习: "Quickstart",
        快速入门: "Quickstart",
        常见问题: "FAQ",
        主页: "Home",
        下载中心: "Download Center",
        使用指南: "Manual",
        登录: "Login",
        注册: "Register",
        前台PC端: "PC FrontDesk",
        windows前台端: "JoyInn Frontdesk Software(Windows)",
        下载: "Download",
        管理端: "Admin APP",
        管理端APP: "APP for Admin",
        保存至电脑: "Scan QR to Download",
        宾客端: "Guest APP",
        宾客端APP: "APP for Guest",
        发卡器驱动下载: "Download Card Reader Driver",
        联系电话: "Telephone",
        联系邮箱: "Contact Us",
        客服微信: "Wechat Service",
        这里是备案号: "粤ICP备13041556号-10",
        首页: "Home",
        多种开门方式安全标准多端应用云端智能化酒店管理产品: 'Cloud based Smart Hotel Management System with industrial safety, across multiple screens, and supporting multiple unlocking methods.',
        JoyInn智能酒店管理系统:'JoyInn Smart Hotel Management System',
        酒店介绍: ` <p>One-Stop Intelligent Hospitality Solution</p>
        <p>JoyInn is dedicated to empowering hotels with cost-effective, high-efficiency digital transformation through a</p>
        <p>cloud-core architecture integrated with multi-terminal collaboration (PC front desk, web console, management</p>
        <p>app, and guest app). The system enables fully automated end-to-end operations, reducing labor costs while</p>
        <p>elevating guest experiences.</p>`,
        中文: "chinese",
        英文: "english",


        一站式完整智能酒店管理方案: 'One Stop Smart Hotel Management Solution',
        便捷入住流程即时客户通知: 'Quick Checkin, Instant Guest Notification',
        房态即时呈现一键发送授权入住无需见面: 'Room status at a glance , One click to authorize, Checkin without face-to-face',
        涵盖PC前台软件后台Web管理酒店管理APP宾客APP: 'Including PC Frontdesk，Web Admin Console，Admin APP adn Guest APP',
        全角色覆盖酒店管理场景集中式数据共享提高管理效率赋能酒店数字化和智能化: 'Fully cover Smart Hotel Management scenarios with multiple roles support.Central data sharing and automatical workflow enable high efficiency',
        多种开锁方式灵活组合: 'Multiple unlocking methods combinations',
        开锁方式p1: 'QR Code, Card, Bluetooth and Pin combination provide guest convenient choices',
        开锁方式p2: ' Besides traditional card support, unique QR code method is more convenient and efficient ',
        开锁方式p3: ' Guest App is not a must. QR code can satisfy access needs',
        二维码介绍: 'Fast, secure and multiple roles support to achieve low cost and high efficiency',
        二维码: 'QR Code',
        密码: 'Pin',
        密码介绍: 'Keypad lock easy to use',
        卡: 'Card',
        卡介绍: 'Secure，easy to use,  traditional user experience',
        蓝牙: 'Bluetooth',
        蓝牙介绍: 'Low energy device, stable connection, low cost of ownership',
        部署灵活联网离线都支持: ' Flexible deployment, online and offline',
        离线支持p1: "Easy Installation, support online deployment with remote setup",
        离线支持p2: "Also support offline deployment, configuring device with Bluetooth or QR code",
        随时随地管理: 'Management Anytime Anywhere',
        随时随地管理p1: ' Managers can check Hotel operation with PC and Mobile at anytime, anywhere',
        随时随地管理p2: 'Room/device status, events/alarms and guest requests/service results are all in hand',
        自动化时钟校准: 'Automatic Clock Synchronization',
        自动化时钟校准p1: 'Cloud automatically synchronize the clock of locks via remote execution either periodically or alarm triggling ', 
        自动化时钟校准p2: 'Daily bluetooth and QRcode activities by staffs automatically synchronize the clock', 
        联系电话: 'Phone',
        联系邮箱: 'Email',
        JoyInn酒店公众号: 'JoyInn Public Account',
        客服微信号: 'Client Service via Wechat',
        安装指南: 'Installation Guide',
        快速开店: 'Quick Hotel Setting',
        日常操作: 'Daily Operation',
        运营管理: 'Operation Management',
        Joylnn酒店帮助中心: 'Joylnn System Help Center',
        JoyInn酒店系统: 'JoyInn System',

        //升级中文字提示
        正在升级中: 'In Construction',
        正在升级中p1: `Dear Vistor<span style="color:#FD8E4D;"> JoyInn's tutorials</span> is in construction`,  
        正在升级中p2: 'Will update soon.Thanks for your support to <span style="color:#FD8E4D"> JoyInn </span>!', 
        集团账号介绍:  `<p>A group account is a master account corresponding to a hotel group. The group account needs to be</p> 
        <p>applied by submitting detailed information to system administration, and after being reviewed, it</p> 
        <p>will be created and confirm back with email.</p>
        <p>The functionalities of a group account include:</p>
        <p>1, The ability to create and manage one or more hotel accounts.</p>
        <p>2, The ability to function as a third-party server account, interfacing with JoyInn's cloud services via</p>
        <p>API.</p> `,
        账户体系:'Accounts',
        概述:'Overview',
        集团账号:"Group Account",
        酒店账号:"Hotel Account",
        酒店账号介绍:`<p>Hotel accounts belong to a group account and correspond to a specific hotel under the group. A hotel</p>        
        <p>account is created by logging into the JoyInn console with the group account.</p>
        <p>The hotel account has the functionality to manage all hotel operations, including:</p>
        <p>Management of the hotel's basic information.</p>
        <p>Management of buildings/floors/rooms/public doors/elevators.</p>
        <p>Management of hotel lock devices, including locks, gateways, access control, power switches, and</p>
        <p>elevator controls.</p>
        <p>Management of staff.</p>
        <p>Management of staff permissions.</p>
        <p>Management of guest check-in.</p>
        <p>Management of hotel tasks.</p>`,
        员工账号:'Staff Account',
        员工账号介绍:`<p>Staff accounts are associated with a hotel account and are created by logging into the front desk</p>
        <p>software using the hotel account. When creating an staff account, it is necessary to assign a specific role</p>
        <p>to the staff. Different roles have different permissions, which determine the software the employee can</p>
        <p>access and the operations they can perform within that software. For further details, please refer to</p>
        <p>"Quick Start - Staff Management."</p>`,
        系统框架:'Architecture',
        软件系统:'Software Subsystem',
        软件系统及功能:'Software Subsystem and Functionalities',
        软件系统及功能介绍:`<p style="padding-bottom: 20px;">Web Management（Web Console）</p>
        <p>Provides group accounts and hotel accounts with anytime, anywhere online management</p>
        <p> capabilities that can be used without the need for installation.</p>
        <p>Owners of group accounts and hotel accounts can access the web console by visiting: </p>
        <p style="padding-bottom: 20px;">https://console.joyinn.net</p>
        <p style="padding-bottom: 20px;">Hotel Front Desk Software (Frontdesk Software)</p>
        <p>Owners of hotel accounts and employee accounts with appropriate permissions can use the front</p>
        <p>desk software to perform daily hotel management tasks. These tasks include room status</p>
        <p>management at the workstation, guest check-in management, management of the hotel's internal</p>
        <p>entities and devices, management of employee accounts and permissions, and management of</p>
        <p>internal hotel tasks.</p>
        <p style="padding-bottom: 20px;">The front desk software must be downloaded and installed from http://www.joyinn.net.</p>
        <p style="padding-bottom: 20px;">Mobile Applications (JoyInn Apps)</p>
        <p>Mobile applications include the management terminal (JoyInn Admin App) and the guest terminal</p>
        <p>(JoyInn Guest App). Owners of hotel accounts and employee accounts with corresponding permissions</p>
        <p>can use the JoyInn Admin App to access permissions and operate management devices, execute</p>
        <p>internal tasks, and more on their mobile devices. Hotel guests can use the JoyInn Guest App to obtain</p>
        <p>lock access permissions and access hotel services. The mobile applications can be downloaded from</p>
        <p style="padding-bottom: 20px;">the app store.</p>
        <p style="padding-bottom: 20px;">Voucher Check Software</p>
        <p>The voucher check software is used to verify and redeem service vouchers for guests, such as breakfast</p>
        <p>vouchers, etc. Hotel employees with voucher check permissions can use the voucher check software to</p>
        <p>verify and deduct internal vouchers for guests. The voucher check software needs to be downloaded</p>
        <p style="padding-bottom: 40px;">and installed from http://www.joyinn.net.</p>`,
        下载及安装:'Download and Installation',
        下载及安装介绍:`<p>1）Download page：http://www.joyinn.net/#/home/download</p>
        <p>2）Before install PC Frontdesk, Card Reader Driver should installed</p>
        <p>3）Download and install PC FrontDesk</p>
        <p>4）Download and install Voucher Check Software if you want to subscribe the advanced service</p>
        <p style="padding-bottom: 20px;">package</p>
        <p style="padding-bottom: 20px;">Mobile App installation can be done by either way below</p>
        <p>1) Scan the QR in the web page, jump to Apple Appstore or Google Play or other store to install</p>
        <p>2) In App store, search JoyInn Admin or JoyInn Guest to install</p>`,
        硬件设备:'Hardware Devices',
        设备总览:'Overview',
        '系统支持的硬件设备包括:':'The hardware devices supported by system includes',
        '门锁,门禁,梯控,取点开关,网关,发卡器,二维码扫描器。':'door lock，access controller，elevator controller，power switch，IoT gateway，card issuer and QR scanner.',
        门锁:'Door Lock',
        门锁主要参数规格:'Technical Specification',
        门禁:'Access Contrller',
        门禁主要参数规格:'Technical Specification',
        梯控:'Elevator Controller',
        梯控介绍:`<p>Elevator Controller comprises access controller and relay array. The relay array</p>
        <p>can support 8, 16, 32 ports.</p>`,
        网关:'Gateway',
        网关设备主要参数规格:'Technical Specification',
        取电开关:'Power Switch',
        取电开关主要参数规格:'Technical Specification',
        设备通用操作:'General Operations on Devices ',
        设备通用操作介绍:`<p>Doorlock/Access Controller/Elevator Controller/Gateway</p> 
        <p>Activation(App Screenshot)</p>
        <p>Gateway configuration of Wifi access parameters(APP Screen shot)</p> 
        <p>Gateway configuration of Wifi access parameters(APP</p>
        <p>Screen shot)</p>
        <p>Power Switch Adding(App Screenshot)</p>
        <p>Power Switch Deletion(App Screenshot)</p>`,
        快速学习:'Quick Start',
        创建酒店:'Hotel Setup',
        集团账号申请:'Group Account Application',
        集团账号申请介绍:`<p>In Joyinn Web site, click "login", then in the pop up login panel select "register，input the required</p>
        <p>information and click "submit.</p>        
        <p><span style="color: red;">*</span><span style="color: #a7a7a5;">wait for the approval by administration. After review, the group account will be created and the</span></p>
        <p><span style="color: #a7a7a5;">detailed login information will be sent via email</span></p>`,
        创建门店账号:'Create Hotel Account',
        创建门店账号介绍:`<p>After group account is ready，it can be used to login the web console via Joyinn</p>       
        <p>Web site. In the web console, </p>
        <p>a)Please complete the basic information of the group</p>
        <p>b)Create the hotel account(s) under the group</p>
        <p>c) Initialize a batch of cards using the blank cards for the group if needed </p>`,
        创建酒店实体:'Create Hotel Entities',
        用酒店账号登录PC端前台软件:'Use the hotel account created in previous step to login the PC Front desk software',
        完善酒店的基本信息:'a)Complete the basic information of the hotel ',
        创建楼栋:'b) create buildings',
        创建楼层:'c) create floors',
        创建房型:'d)create room types',
        创建房间:'e) create rooms',
        创建公共门:'f) create common doors',
        创建电梯区域:'g)create elevator areas',
        绑定设备:'Devices Binding',
        绑定设备介绍:`
        <p>Option1: use hotel account to login Admin App</p>
        <p>1) Binding to room</p>
        <p>In room status page,  click the room which is the binding target.</p>
        <p>In the room details page,  click "add device".</p>
        <p>select the device type, lock or power switch.</p>
        <p>then either select the device from existing list or click</p>
        <p>add/activate new device. </p>
        <p>2)Binding to common doors/elevator area</p>
        <p>In room status page, click the common door or elevator area</p>
        <p>In the details page,  click "add device".</p>
        <p>then either select the device from existing list or click</p>
        <p>add/activate new device.</p>
        <p style="padding-top: 80px;">Option2:use hotel account to login Admin App</p>
        <p>In device management tab, activate locks/access controller/elevator controller. When</p>
        <p>activate, please set alias which will help to be identified later</p>
        <p>Add power switch if applicable and set alias too.</p>
        <p>Login PC Frontdesk Software and do below operation.</p>
        <p>In the room management page, select room and bind the corresponding lock device and</p>
        <p>power switch</p>
        <p>In the common door management page, select common door and bind access controller device</p>
        <p>In the elevator area management page, select elevator area and bind elevator controller(access controller)</p>`,
        '绑定后的设备配置(无关网关场景)':'Device Configuration after binding(offline deployment without gateway)',
        设备配置:'Device Config',
        设备配置介绍:`<p>For online deployment with gateway, the binding operation will trigger the configuration sending to the</p>
        <p>devices including door locks/access controller/elevator controller/power switch/etc.</p>
        <p>For offline deployment without gateway,  need manually configure the devices one by one using App</p>
        <p>1)Door locks/access controller/elevator controller configuration with App</p>
        <p>Configure the device by showing  the "Configuraion QR" to the devices (App screenshot)</p>
        <p>2) Power switch configuration with App</p>
        <p>Configure the device by bluetooth operation(App screenshot)</p>`,
        入住办理:'Guest Reception',
        房态管理:'Workbench（Room Status）',
        灰色代表着脏房:'Grey Dirty',
        绿色代表已入住:'Green Occupied',
        橙色代表空房:'Orange Vacant',
        蓝色代表维修房:'Blue Maintenance',
        右上角多个图标代表同时多个状态:'Multiple icons represent combined status',
        房态管理介绍:`<p>At the right pannel of  workbench, can use filter to query room with different status</p> 
        <p>and do the quick card action and shortcut to tasks management</p>`,
        办理入住:'Check-In',
        办理入住介绍:`<p>Select Building/Floor, locate the target vacant room. Click the room, select the "Authorize" in the popup</p>
        <p>menu, enter room check-in dialog box </p>
        <p>Enter guest information, including name, phone no.(must have country code), email address, breakfast voucher, etc. </p>
        <p>Select check-in date time and departure date time.</p>
        <p>Select the unlocking methods such as QR, card, pin and bluetooth.</p>
        <p>Select extra authorization of common doors and elevator area if needed.</p>
        <p>Check the box "Short Message Notification " if want to send short message to guest. ( Applicable only for the hotel</p>
        <p>already purchase the short message package)</p>
        <p>Complete above actions, please click "Authorize" button.</p>`,
        授权房卡:'Authorize Card',
        授权房卡介绍:`<p>Please note, all the blank cards have to be initialized before they can be put into use.</p>
        <p>Before issue card, should check and make sure the card reader working normally. if there is failure, please refer</p>
        <p>to "FAQ-Card issue" for information</p>
        <p>if authorization is submitted successfully with "card" as one of the unlocking methods,  a prompt for</p>
        <p>"card issuing" next step will show up.</p>
        <p>When issue card, place the card on the card reader. Click the "issue" button, the card will be written</p>
        <p>and success counter be increased. can issue multiple card for one check-in</p>
        <p>When finish, click cancel to quick the UI.</p>`,
        重发授权:'Re-send Authorization',
        重发授权介绍:`<p>During the stay of guest, can resend the existing authorization and issue more cards if needed. </p>
        <p>Quickly access to guest authorization</p>
        <p>Option1: Quick card action.  By using card reader with guest card, can quickly resend the authorization</p>
        <p>Option2:  In the workbench, locate the guest room, click the room icon  and select "Authorize"</p>
        <p>to enter the Authorization Page.</p>`,
        授权变更:'Change Authorization',
        授权变更介绍:`<p>During the stay of guest, can change the existing authorization if needed. for example,  the</p>
        <p>guest extend the stay or add more guests to the room</p>
        <p>Quickly access to guest authorization</p>
        <p>Option1: Quick card action.  By using card reader with guest card, can quickly change authorization</p>
        <p>Option2: In the workbench, locate the guest room, click the room icon  and select "Authorize" to</p>
        <p>enter the Authorization Page</p>`,
        卡片挂失:'Card Lost',
        卡片挂失介绍:`<p>In the workbench, locate the guest room, click the room icon and select "card lost". after</p>
              <p>that, the "lost card" related to the check-in room will become invalid.</p>`,
        退房:'Check-out',
        退房介绍:`<p>Option1: Quick card action.  By using card reader with guest card, can quickly check-out</p>
        <p>Option2: In the workbench, locate the guest room, click the room icon  and select</p>
        <p>"Check-out"</p>`,
        变更房态:'Change Room Status',
        变更房态介绍:`<p>If the room status is "dirty" or "maintenance",  it can be manually changed via the popup menu, if house</p>        
        <p>keeping or maintenance task has been performed.</p>`,
        员工管理:'Staff Management',
        '角色（岗位）定义':'Role(Position)',
        '在酒店业务场景中我们存在多个职能，如：':'In hotel service scenario, there are several roles, such as',
        '前台负责办理入住、续房、退房;':'Frontdesk receptionist responsible for Guest Check-in, extending stay, check-out, etc.',
        '清洁工负责清洁房问;':'Housekeeper responsible for cleaning service.',
        '维修人员，负责检修房间内相关设施，以及酒店区域其他设备，如电梯、网络等相关硬件设备;':'Maintenance Staff responsible for repair work of room devices and hotel equipment in common areas.',
        角色介绍:`<p>The system defines the following roles:</p>
        <p>Admin: Administrator role, allowed to log in to the console, front desk, and the management interface of the Admin App. Can</p>
        <p>manage the entire hotel, room status, and equipment.</p>
        <p>Reception: Front desk role, allowed to log in to the front desk system. Can operate room status and handle all processes related</p>
        <p>to guest check-in.</p>
        <p>HouseKeeper: Housekeeping role, allowed to log in to the employee interface of the Admin App. Can receive and process</p>
        <p>cleaning tasks.</p>
        <p>Maintenance: Maintenance worker role, allowed to log in to the employee interface of the Admin App. Can receive and process</p>
        <p>maintenance tasks.</p>
        <p>RoomService: Room service role, allowed to log in to the employee interface of the Admin App. Can receive and process room</p>
        <p>service requests, such as delivering guest supplies.</p>
        <p>Voucher Checker: Voucher verification role, allowed to log in to the voucher verification interface. Can verify vouchers related to</p>
        <p>guest orders, such as breakfast vouchers.</p>
        <p>The default role for a hotel-registered account is Admin, which has management and operational permissions for all devices.</p>
        <p>Additionally, employee accounts for various roles can be created via the console or front desk. The relationship between</p>
        <p>employee accounts and roles can be one-to-many, with specific roles selected during creation based on the UI display.</p>`,
        员工授权:'Staff Authorization',
        员工授权介绍:`<p>After creating an employee account, you can assign the required permissions to the corresponding employee.</p>
        <p>For ease of use, the system provides the following permission templates:</p>
        <p>Master Card (Code), Building, Floor, and Custom Combinations.</p>
        <p>On the Employee Management page, select the employee column, click More Actions, choose Authorize, and then enter</p>
        <p>the Authorization pop-up window to assign permissions to the employee.</p>      
        <p>Can also issue card to staff if necessary.</p>`,
        授权管理:'Authorization Management',
        '以酒店账号登录console或者登录PC前台，可以在“权限管理”页面，看到所有员工的授权列表。':'When logging in to the console or PC front desk with a hotel account, you can view the authorization list of all employees on the Permission Management page.',
        '可以通过这个列表修改和删除某个员工的已有授权。':'This list allows you to modify or delete existing authorizations for specific employees.',
        '员工权限使用（房卡功能）':'Staff Authorization Usage( Admin App and "room card")',
        员工权限介绍:`<p>Employee permissions are primarily exercised through the Admin App. By logging into the Admin App with an employee account, you can access the "Room Card" page to view the authorized "Room Card" permissions.</p>
        <p>Upon opening the "Room Card", the corresponding QR code will be displayed. This QR code can unlock all permitted lock devices, including rooms, public doors, elevators, and more.</p>
        <p>In addition to the QR code, employees can also click the "Bluetooth" button on the Room Card page near rooms, public doors, elevators, etc., to view the corresponding Bluetooth unlock buttons for nearby devices and tap to unlock.</p>
        <p>They can also click the "Pin" button to retrieve Pin for nearby devices and unlock them via Pin</p>`,
        增值服务:'Value Added Services',
        增值服务及开通:'Value Added Services and subscription',
        '目前系统提供的增值服务包括:':'The value-added services currently provided by the system include:',
        '短信通知服务:用于发送宾客入住通知。':'SMS Notification Service: Used to send check-in notifications to hotel guests.',
        '酒店任务管理:保洁，维修，客房服务的任务处理工作流管理。':'Hotel Task Management: Workflow management for handling tasks such as housekeeping, maintenance, and room service.',
        '卡券核销:用于酒店宾客卡券的自动核销管理，如早餐券等':'Voucher Redemption: Automated redemption management for hotel guest vouchers (e.g., breakfast vouchers).',
        '增值服务的开通，通过酒店账户登录前台软件或者web console，订购相应的套餐。':'To access value added services，need to subscribe the corresponding package via Frontdesk software or web console.',
        酒店任务管理:`Hotel Tasks Management`,
        '主要针对保洁、维修、前台等人员的任务管理，可查看任务的详情及结束当前任务状态':`This task management is for housekeeper, maintenance and receptionist. They can check task details and manage the status.`,
        '宾客可以通过web页面或者GuestApp发起请求，系统会产生相应的任务。':'Guests can submit requests through the web page or Guest App, and the system will generate corresponding tasks.',
        '保洁、维修、客房服务等人员，可以通过Admin App查看，接收，处理任务。宾客发起任务界面。':'Housekeeping, maintenance, and room service staff can view, receive, and handle tasks through the Admin App.',
        '保洁，维修，客房服务人员接收和处理任务界面。':'Above is task reception and handling interface in front desk software.',
        卡券核销:'Voucher Checking',
        '入住时，系统可以分配宾客相应的卡券数量，如早餐券等':'When check-in, system can assign voucher to guest, such as breakfast voucher',
        '宾客在相应的场所，能够轻而易举地通过房卡或者二维码进行卡券核销和使用。':'When guest want to get related service, he can use room card or QR code to pass the system checking.',
        '短信/邮件':'Short Message/Email',
        '邮件是必不可少，在注册、登录、接收房间钥匙等都需要通过邮件方式，':'Emails are essential for account registration, login, and guests receiving room keys.',
        '除了邮件以外，系统提供短信通知服务，不方便使用邮件的宾客，可以通过短信接收入住授权通知。':'For guests who prefer not to use email, the system also offers SMS notifications. In this way,',
        '短信通知服务，需要酒店预先购买额度。':'guest can receive check-in authorization and other important notifications via text message.',
        '发送短信，会实时扣减酒店的额度':'Before using Short Message Service,  hotel need to buy SMS quota. Sending SMS will deduct the quota balance of hotel.',
        '酒店可以在前台软件或者web console上，查询发送的历史记录，当前的额度等信息。':'Hotel can query the sending history and current balance via Frontdesk software or web console.',
        数据统计:'Data Statistics',
        '房间数据统计:观察流量走势，我们可以判断订房的流量高峰期，从而控制房价的价格和库存':'Room Data Statistics: By observing traffic trends, we can identify peak booking periods to adjust room rates and inventory control.',
        '开门活跃数据:分别蓝牙、房卡、二维码、密码开锁方式，能够精准把握其返穗时间和位置。':'Door Opening Activity Data: Categorizing Bluetooth, key card, QR code, and passcode unlocking methods allows precise tracking of return times and locations.',
        '套餐使用数据:统计出邮件、短信等套餐的已使用、未使用等数据，能够精准了解宾客使用情况。':'Package Usage Data: Statistics on used/unused email, SMS packages enable accurate understanding of guest usage patterns.',
        '房间状态统计:同步统计房价实时状态空房、电量不足、已入住、维修、脏房状态。':'Room Status Statistics: Real-time synchronization of room states - vacant, low battery, occupied, under maintenance, dirty room status.',
        '报警数据:为酒店、宾客安全保驾护航':'Alert Data: Safeguarding hotel and guest security.',
        '设备状态统计:设施设备状态关系到酒店安全，服务质量，销售甚至利润。及时关注设施设备正常运行保障酒店宾客人生安全。':'Equipment Status Statistics: Facility conditions impact hotel safety, service quality, sales, and profits. Timely monitoring ensures normal operations and guest safety.',
        '通过对酒店的入住率、房间利用利率等数据的分析，提高酒店的运营效率。提高营销效果，通过数据中台，可以更好地了解市场需求':'Through analyzing hotel occupancy rates and room utilization rates, operational efficiency can be enhanced. Improved marketing effectiveness is achieved by leveraging the data middle platform to better understand market demands.',
        宾客指南:'Guest Guide',
        宾客入住通知:'Guest Notification',
        宾客入住介绍:`<p>After check-in, the guest will receive notification via email or SMS(depends on the hotel's</p>       
        <p>choice)</p>`,
        宾客房卡:'Guest Service Page',
        '房卡入口（H5页面）':'"Room Card"(H5 Page)',
        '宾客点击短信或者邮件里的链接，可以直接打开房卡页':'Guest click the link in the Email or SMS to open the service page for the check-in order',
        桌面快捷方式:`<p class="text">Tip:  can save the web page as shortcut icon in Mobile phone desktop for later quick access</p>
        <p style="color: #D30909;padding-bottom: 10px;">iPhone safari browser example</p>
        <p style="color: #D30909;padding-bottom: 30px;">android chrome browser example</p>
        <p class="text">Guest App(Mobile)</p>
        <p>Guest can download the App by click the down link in the service main page to jump to App store.</p>`,
        '下载安装Guest App后，宾客可以通过入住时登记的邮箱或者手机号登录Guest App，登录时系统会把验证码发到宾客的邮箱作':'After download Guest App, Guest can use the phone no or email provided for check-in to login Guest App. System will',        
        为登录鉴权:'send verification code to Guest via Email or SMS as a way of authentication.',
        登录截图示例:'Login screenshot example',
        'Guest App具有房卡页面的功能':'Guest App also has "room card" function',
        一码通行:'One QR for all accesses',
        '宾客使用房卡里的二维码，可以在入住期间，在酒店的授权范围内无障碍通行。':'Guest can use the QR in "Room Card" tab to access all authorized areas during his/her stay ',
        宾客卡券服务:'Guest Voucher Services',
        宾客卡券介绍:`<p class="text">Breakfast Voucher</p>
        <p>Guests can use QR codes or physical cards for authorization verification when entering the breakfast area:</p>
        <p>Click to open the room key page or access the room key page in the App, then scan the room key QR code using the</p>
        <p>QR scanner on the breakfast verification device;</p>
        <p>Swipe the room IC card on the card reader of the breakfast verification device. The system will automatically deduct</p>
        <p style="padding-bottom: 40px;">the corresponding breakfast voucher.</p>
        <p class="text">Other Voucher</p>
        <p>Please refer to the service information of hotel or consult with the service staffs</p>`,
        发卡问题:'Card Encoder',
        常见问题:'FAQ',
        '「提示检查发卡器和本地服务」':'「Prompt for checking the local service and card reader device」',
        提示检查发卡器和本地服务介绍:`<p>Answer:</p>
        <p>If the card encoder driver is not installed, please download and install it.</p>
        <p>If already installed, check whether the card encoder is properly connected. If the computer fails to recognize the</p>
        <p>device, reconnect the encoder or try removing other USB devices before retrying.</p>
        <p>Finally, verify that the card encoder service is running normally.</p>
        <p>To confirm this, navigate to the following directory:</p>`,
        点击执行demo:'Click to execute demo',
        '在demo页面点击open，如果成功，就表明安装正确，如果失败，需要寻找失败原因并重新安装。':'In demo page, click "open", if  succeed,  it mean the installation of card encoder is correct. if fails, need to check and install again.',
        '需要注意：windows defender或者杀毒软件有可能会误删除发卡驱动。':'Please note, windows defender or anti-virus software may delete the card encoder driver.',
        '「发卡的时候提示：请用新卡片重试」':'「Prompt:  Please try with new card」',
        '「发出的卡片无法开锁」':'「The card issued can not unlock」',
        卡片重试解答:`<p>Answer: One possible reason may be the card is blank card without initialization. Please initialize it before next try. The other</p>
        <p>reason is the card may be used by other hotel already. Different hotel's cards can not be mixed</p>`, 
        无法开锁解答:`<p>Answer: The reason may be due to the wrong binding configuration of the lock or the internal clock of the lock is not correct.</p       
        <p>refer to the FAQ of lock for more information.</p>`,
        办理入住:'Check-In',
        '「办理入住时，宾客信息如名字，手机号，邮箱等，都是必须填的吗？」':'「During check-in, is the guest information such as name, phone number, and email required to be filled in?」',
        入住信息解答:`<p>Answer: Yes, all of this information must be filled in. The email is used to receive check-in notifications and the</p>     
        <p>corresponding QR code key. In the case of selecting SMS notification services, the phone number is used to receive SMS</p>
        <p>notifications. Additionally, the email or phone number can also serve as the login credentials for the guest app.</p>`,
        '「办理入住时给宾客发了蓝牙钥匙，宾客如何使用?」':'「How the guest use the bluetooth key sent by check-in process?」',
        蓝牙钥匙解答:`<p>Answer:</p>
        <p>· Step 1: Download the Guest App.</p>
        <p>· Step 2: Log in using the registered phone number or email address. During login, the system will send a verification</p>
        <p>code to the corresponding email or phone number.</p>
        <p>· Step 3: After logging into the Guest App, guests will see the hotel and room they are staying in and can click to enter</p>
        <p>the room detailed page.</p>
        <p>· Step 4: Please ensure that the Bluetooth on your phone is turned on, get close to the door lock, and click to unlock.</p>`,
        '「提前退房如何删除宾客的开门方式?」':'「How to cancel the unlocking authorization of the early check-out guest」',
        提前退房解答:`<p>Answer:</p>
        <p>For guest check-out operations, if it is an early check-out, the system will handle the authorization for the unlocking methods as</p>
        <p>follows:</p>
        <p>· Bluetooth Key: The system will immediately delete the unlocking authorization for the Bluetooth key.</p>
        <p>· Cards and QR Codes:</p>
        <p>If the system is online-deployed, it will immediately delete the relevant authorization information via the network on the devices.</p>
        <p>If the system is offline-deployed, there are two ways to delete the access method:</p>
        <p>·Issue new cards or QR codes to new guests. If the new guest uses  card or QR code to unlock, the old guest's card/QR code will</p>
        <p>automatically become invalid.</p>
        <p>·Have staff scan the deletion QR code generated on the APP on the lock to remove the previous guest's card and QR code</p>
        <p>permissions.</p>
        <p>· Password:</p>
        <p>If the system is online-deployed, it will immediately delete the relevant authorization information via the network on the devices.</p>
        <p>If the system is offline-deployed, staff can scan the deletion QR code generated on the APP on the lock to remove the previous</p>
        <p>guest's password.</p>`,
        '「续住后原来的开门方式还可以使用吗?」':'「if extend stay, the old unlocking key is still valid?」',
        续住后解答:`<p>Answer:</p>
        <p>After extending the stay, the original access methods can only be used until the previous expiration date, so new keys must be issued.</p>
        <p>· Card: Requires reissuing the card.</p>
        <p>· Bluetooth Key: Automatically updates after extending the stay.</p>
        <p>· Password: Requires reissuing.</p>
        <p>· QR Code: Requires reissuing.</p>`,
        房卡管理:'Card Management',
        '「如何查询一张卡片的内容」':`「How to query a card's content?」`,
        查询卡片解答:`<p>Answer: Ensure the card reader is working properly. Open the "Workbench" -> "Card Quick Operation" interface to quickly query</p>
        <p>card contents, including guest cards and staff cards.</p>`,
        '「如何退卡」':'「How to return card?」',
        退卡解答:`<p>Answer: After obtaining the card, ensure the card reader is working properly. Open the "Workbench" -> "Card Quick Operation"</p>
        <p>interface to perform a return card operation, including guest cards and staff cards. If the guest's card is the only one, the return</p>
        <p>card operation will trigger the check-out process. If it is a staff card, the system will delete the corresponding card record.</p>`,
        '「如何续卡」':'「How to extend stay of card?」',
        续卡解答:`<p>Answer: After obtaining the card, ensure the card reader is working properly. Open the "Workbench" -> "Card Quick Operation"</p>
        <p>interface to perform a card renewal operation, including guest cards and staff cards. Renewing a guest card will trigger the check-</p>
        <p>in extension process, equivalent to extending the stay.</p>`,
        '「如何挂失卡」':'「How to deal with Card lost?」',
        挂失卡解答:`<p>Answer: to Report a Lost Guest Card, please open the "Workbench," click on the corresponding room, and in the pop-up menu,</p>
        <p>select "Card Lost." All cards issued during check-in for that room will be marked as lost, and corresponding authorizations will be</p>
        <p>canceled.</p>
        <p>To report a Lost Staff Card: Search for the card's authorization record (you can search by employee name), open the action menu</p>
        <p>for the corresponding record, and select "Card Lost." After reporting a staff card as lost, new cards can be reissued through</p>
        <p>employee authorization management.</p>`,
        '「如何发员工卡（总卡/楼栋/楼层/自定义组合）」':'「How to issue staff card?」',
        发卡解答:`<p>Answer:Issuing a card to an employee requires creating an employee account first. Once created successfully, you can go to the</p>
        <p>"Employee Management" page, select the employee, and perform authorization operations. If the authorization includes a card</p>
        <p>as an unlocking method, the system will issue the card during the authorization creation process.</p>`,
        设备:'Devices',
        '「JoyInn目前可以支持几种设备」':'「What are the devices JoyInn support 」',
        智能锁:'Door locks',
        门禁:'Access Controller',
        梯控:'Elevator Controller',
        取电开关:'Power Switch',
        网关:'Gateway',
        '可查看开锁记录、关联状态、联网详情、远程开锁、异常记录等':'Can query unlocking history, binding status, networking information, alarms,etc.',
        '「发卡器连接失败是什么原因」':'「Any reason leads to unrecognition of card encoder?」',
        发卡器连接失败解答:`<p>Answer:The computer does not recognize the card encoder. Please try unplugging and replugging the card encoder again, or remove</p>
        <p>other USB devices and try again.</p>`,
        '「如何添加锁」':'「How to add lock」',
        '解答：参见“硬件设备”部分':'Answer: refer the "Hardware Device" part.',
        '「如何删除锁」':'「How to delete lock」',
        '解答：参见“硬件设备”部分':'Answer: refer the "Hardware Device" part.',
        '「卡片/二维码开锁失败，如何判断原因」':'「Card or QR unlocking fails. How to check the reason」',
        开锁失败解答:`<p>Answer:different reasons will lead the different LED and beeper behavior.</p>
        <p>LED Status:</p>
        <p>1 - Card User number error</p>
        <p>2 - Deregistered number error (card has been deregistered)</p>
        <p>3 - Start date is later than the current date</p>
        <p>4 - Start date is the same as the current date, but start time is later than the current time</p>
        <p>5 - End date is earlier than the current date</p>
        <p>6 - End date is the same as the current date, but end time is earlier than the current time</p>
        <p>7 - No permission</p>
        <p>8 - Not within the specified time period for unlocking</p>
        <p>9 - Door is bolted (no permission to unlock bolted)</p>
        <p>Sound Prompts:</p>
        <p>One beep: Time error (LED 3, 4)</p>
        <p>Three beeps: Data error (LED 1, 2)</p>
        <p>Four beeps: No permission (LED 7)</p>
        <p>Two beeps: Card expired (LED 5, 6, 8)</p>
        <p>No beep: Locked (LED 9)</p>
        <p>Five beeps: Unable to decrypt, or incorrect lockid or regionid   </p>
        <p>By cheking the behavior, can know the failure reason.</p>
        <p style="padding-top: 20px;">Another direct way to check the reason is to watch the unlocking failure event in Frontdesk software.</p>`,
        '「如何查看锁的本地时钟？」':'「How to check the internal clock of the lock device」',
        查看锁的本地时钟解答:`<p>In the case of online deployment, the lock's clock can be queried in the "Device" menu of the front desk</p>
        <p>software. The lock's local clock has a time deviation of no more than 10 minutes within one year. In an</p>
        <p>online deployment scenario, the system will periodically synchronize the lock's clock.</p>
        <p>In the case of offline deployment, hotel staff need to use a configuration code to synchronize the clock</p>
        <p>every six months or do it when replacing the battery.</p>`,
        系统架构与技术亮点:'System Architecture & Technical Highlights',
        系统架构与技术亮点介绍:`<p>1, IoT Integration</p>
        <p>Unified management platform integrating smart locks, gateways, access control, elevator systems, and power</p>
        <p>switches via standardized IoT protocols.</p>
        <p>Low-Power Connectivity: Gateways utilize Sub-1GHz wireless technology for device communication, supporting</p>
        <p>dual-mode Wi-Fi/Ethernet connectivity to adapt to diverse network environments.</p>
        <p>Edge Computing Framework: Cloud-local synergy ensures uninterrupted PC front desk operations during internet</p>
        <p>outages, guaranteeing 24/7 business continuity.</p>
        <p>Device Management: Affordable, plug-and-play hardware solutions with remote monitoring, configuration</p>
        <p>synchronization, and clock calibration for system stability.</p>
        <p>2,Enhanced Guest Experience</p>
        <p>Contactless Check-In: Multi-mode authorization (QR code, Bluetooth, PIN, physical card) with full access</p>
        <p>functionality without mandatory app downloads.</p>
        <p>Smart Service Interaction (via Guest App):</p>
        <p>Real-time front desk communication & room service booking</p>
        <p>Live service progress tracking & notifications</p>
        <p>Personalized request submission & feedback</p>
        <p>3, Operational Empowerment</p>
        <p>Staff Management:</p>
        <p>Role-based permissions (front desk, housekeeping, maintenance)</p>
        <p>Mobile task assignment & execution tracking</p>
        <p>Efficiency Tools:</p>
        <p>Real-time room status updates, one-click authorization, streamlined check-in</p>
        <p>Automated workflow routing & instant staff notifications</p>
        <p>Cost Optimization: reduction in communication overhead and lower operational costs.</p>
        <p>4, Data-Driven Management</p>
        <p>Holistic Visibility: Monitor hotel operations via web console or management app, including:</p>
        <p>Device status & alarm alerts</p>
        <p>Room service request fulfillment rates</p>
        <p>Energy consumption & facility usage analytics</p>
        <p>Rapid Deployment: short upgrade cycle for existing hotels, significant cost reduction for new smart</p>
        <p>hotel deployments.</p>`,
        核心价值:'Core Value Proposition',
        核心价值介绍:`<p>JoyInn delivers lightweight architecture, high ROI hardware, and fully automated workflows to</p>
        <p>accelerate hotels' intelligent transformation, offering guests seamless experiences and enabling</p>
        <p>data-driven management.</p>`,
        关于我们:'About Us',
        公司简介:'Company Profile',
        简介介绍:`
        <p>We specialize in addressing IoT demands and applications across vertical industries,           
        delivering comprehensive IoT software/hardware solutions and services for global 
        clients' commercial scenarios. 
        Our core business focuses on smart hotel all-in-one solutions and SaaS services.
        In this domain, we design and develop hardware and software for computers, mobile
        devices, smart locks, gateways, room controls, and more, while providing
        corresponding SaaS services worldwide. Currently serving clients across
        the globe, our solutions and services have effectively supported 
        their business success.</p>`,
        联系我们:'Contact Us',
        '如有疑问或合作，可以通过以下邮箱联系我们:':'Any query or cooperation, please contact us via below email:',
        联系邮箱:'Email',
    },
    智能锁:'Lock',
}