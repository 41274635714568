import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home';
// import HomePage from '@/views/Home'
import Agreement from '@/views/Agreement'
import LayOut from "@/views/LayOut";
import Terms from "@/views/Terms"
// const routerPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function (location) {
//     return routerPush.call(this, location).catch(err => { })
// };

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'Home',
        redirect: "/home/homePage",
        component: Home,
        meta: {
            title: "首页"
        },
        children: [
            {
                path: 'homePage',
                name: 'HomePage',
                meta: {
                    title: '首页页面'
                },
                component: () => import('@/views/HomePage/index.vue'),
            },
            {
                path: 'download',
                name: 'Download',
                meta: {
                    title: '下载中心'
                },
                component: () => import("@/views/Download")
            },
            {
                path: 'introduction',
                name: 'Introduction',
                meta: {
                    title: '快速上手'
                },
                component: () => import("@/views/Introduction")
            },
            {
                path: 'video',
                name: 'Video',
                meta: {
                    title: '快速入门'
                },
                component: () => import("@/views/Video")
            },
            {
                path: 'aboutUs',
                name: 'aboutUs',
                meta: {
                    title: '快速入门'
                },
                component: () => import("@/views/AboutUs")
            },
        ]
    },

    // {
    //     // path: "/",
    //     path: "/useGuide",
    //     name: "UseGuide",
    //     meta: {
    //         title: "使用指南"
    //     },
    //     component: () => import("@/views/UseGuide/index.vue")
    // },
    // {
    //     path: "/downloadCenter",
    //     // path: "/downloadCenter",
    //     name: "DownloadCenter",
    //     meta: {
    //         title: "下载中心"
    //     },
    //     component: () => import("@/views/DownloadCenter/index.vue")
    // },
    {
        path: '/guideDeatil',
        component: LayOut,
        redirect: '/guideDeatil/introduce',
        meta: {
            title: '使用指南详情',
        },
        children: [
            {
                // path: "/",
                path: "introduce",
                name: "Introduce",
                meta: {
                    title: "介绍"
                },
                component: () => import("@/views/Introduce")
            },
            {
                path: "install",
                name: "Install",
                meta: {
                    title: "安装指南"
                },
                component: () => import("@/views/Install")
            },
            {
                path: "accounts",
                name: "accounts",
                meta: {
                    title: "账户体系"
                },
                component: () => import("@/views/Accounts")
            },
            {
                path: "software",
                name: "software",
                meta: {
                    title: "软件系统"
                },
                component: () => import("@/views/Software")
            },
            {
                path: "createHotel",
                name: "createHotel",
                meta: {
                    title: "创建酒店"
                },
                component: () => import("@/views/createHotel")
            },
            {
                path: "checkIn",
                name: "checkIn",
                meta: {
                    title: "入住办理"
                },
                component: () => import("@/views/checkIn")
            },
            {
                path: "staffManagement",
                name: "staffManagement",
                meta: {
                    title: "员工管理"
                },
                component: () => import("@/views/StaffManagement")
            },
            {
                path: "valueAdded",
                name: "valueAdded",
                meta: {
                    title: "增值服务"
                },
                component: () => import("@/views/ValueAdded")
            },
            {
                path: "statistics",
                name: "statistics",
                meta: {
                    title: "数据统计"
                },
                component: () => import("@/views/Statistics")
            },
            {
                path: "guestGuide",
                name: "guestGuide",
                meta: {
                    title: "宾客指南"
                },
                component: () => import("@/views/GuestGuide")
            },
            {
                path: "cardProblem",
                name: "cardProblem",
                meta: {
                    title: "发卡问题"
                },
                component: () => import("@/views/CardProblem")
            },
            {
                path: "checkInProblem",
                name: "checkInProblem",
                meta: {
                    title: "办理入住"
                },
                component: () => import("@/views/CheckInProblem")
            },
            {
                path: "roomCardProblem",
                name: "roomCardProblem",
                meta: {
                    title: "房卡管理"
                },
                component: () => import("@/views/RoomCardProblem")
            },
            {
                path: "deviceProblem",
                name: "deviceProblem",
                meta: {
                    title: "设备"
                },
                component: () => import("@/views/DeviceProblem")
            },
            {
                path: "lockProblem",
                name: "lockProblem",
                meta: {
                    title: "智能锁"
                },
                component: () => import("@/views/LockProblem")
            },
            {
                path: "hardware",
                name: "hardware",
                meta: {
                    title: "硬件设备"
                },
                component: () => import("@/views/Hardware")
            },
            // {
            //     path: "quick",
            //     name: "Quick",
            //     meta: {
            //         title: "快速开店"
            //     },
            //     component: () => import("@/views/Quick")
            // },
            // {
            //     path: "operate",
            //     name: "Operate",
            //     meta: {
            //         title: "日常操作"
            //     },
            //     component: () => import("@/views/Operate")
            // },
            // {
            //     path: "yunying",
            //     name: "Yunying",
            //     meta: {
            //         title: "日常操作"
            //     },
            //     component: () => import("@/views/Yunying")
            // },
            // {
            //     path: "question",
            //     name: "Question",
            //     meta: {
            //         title: "常见问题"
            //     },
            //     component: () => import("@/views/Question")
            // },
        ]
    },
    {
        path: "/privacy ",
        name: "Privacy",
        component: Agreement
    },
    {
        path: "/terms ",
        name: "Terms",
        component: Terms
    },
]

const router = new VueRouter({
    // mode: 'history',
    routes
})
//导航冗余
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

// 跳转后自动返回页面顶部
router.afterEach(() => {
    window.scrollTo(0, 0);
})

export default router