// zn.js
export default {
  main: {
    视频教程: "视频教程",
    介绍: "介绍",
    联系我们: "联系我们",
    快速学习: "快速学习",
    快速入门: "快速入门",
    快速上手: "快速上手",
    入住办理指南: "入住办理指南",
    员工管理: "员工管理",
    设备管理: "设备管理",
    常见问题: "常见问题",
    发卡问题: "发卡问题",
    办理入住: "办理入住",
    房卡管理: "房卡管理",
    软件系统: "软件系统",
    设备: "设备",
    智能锁: "智能锁",
    主页: "主页",
    下载中心: "下载中心",
    使用指南: "使用指南",
    登录: "登录",
    注册: "注册",
    前台PC端: "前台PC端",
    windows前台端: "windows前台端",
    下载: "下载",
    管理端: "管理端",
    管理端APP: "管理端APP",
    保存至电脑: "扫码下载",
    宾客端: "宾客端",
    宾客端APP: "宾客端APP",
    发卡器驱动下载: "发卡器驱动下载",
    联系电话: "联系电话",
    联系邮箱: "联系邮箱",
    客服微信: "客服微信",
    用微信扫一扫: "用微信扫一扫，添加客户微信",
    这里是备案号: "粤ICP备13041556号-10",
    首页: "首页",
    多种开门方式安全标准多端应用云端智能化酒店管理产品:'多种开门方式、安全标准、多端应用、云端智能化酒店管理产品,',
    JoyInn智能酒店管理系统:'JoyInn智能酒店管理系统',
    酒店介绍: ` <p>JoyInn是一站式智慧酒店解决方案，致力于为酒店提供低成本、高效率的智能化转型服务，通过云端核心系统</p>   
    <p>与多终端协同架构（PC前台、Web控制台、管理端App及宾客App），实现全流程自动化运营，显著降低人力</p>
    <p>成本，同时提升宾客体验。</p>`,
    JoyInn酒店帮助中心: "JoyInn酒店帮助中心",
    智能化酒店管理软件: "joyInn酒店适用于集中式酒店、公寓、民宿，是一款智能化酒店管理软件",
    点我: "点我",
    JoyInn酒店快速上手: "JoyInn酒店快速上手-1min",
    添加锁等视频教程: "下载与安装、创建酒店、新增客房、公共门、添加锁等视频教程",
    员工及设备管理: "快速上手、入住办理指南、员工及设备管理",
    软件使用过程中遇到的问题这里都可以解决: "软件使用过程中遇到的问题这里都可以解决",
    云端智能化酒店管理产品: "多种打开方式、5种主流语言、OWASP安全标准、多端应用、云端智能化酒店管理产品",
    下载与安装: "下载与安装",
    创建酒店: "创建酒店",
    新增客房: "新增客房",
    公共门: "公共门",
    加锁: "加锁",
    添加网关: "添加网关",
    添加取电开关: "添加取电开关",
    添加梯控: "添加梯控",
    新建员工: "新建员工",
    给客人发卡: "给客人发卡",
    给客人发蓝牙钥匙: "给客人发蓝牙钥匙",
    给客人发密码: "给客人发密码",
    续房: "续房",
    退房: "退房",
    发总卡: "发总卡",
    发卡操作: "发卡操作",
    挂失房卡: "挂失房卡",
    房卡: '房卡',
    中文: "中文",
    英文: "英文",


    一站式完整智能酒店管理方案: '一站式完整智能酒店管理方案',
    便捷入住流程即时客户通知: '便捷入住流程，即时客户通知',
    房态即时呈现一键发送授权入住无需见面: '房态即时呈现，一键发送授权，入住无需见面',
    涵盖PC前台软件后台Web管理酒店管理APP宾客APP: '涵盖PC前台软件，后台Web管理，酒店管理APP，宾客APP',
    全角色覆盖酒店管理场景集中式数据共享提高管理效率赋能酒店数字化和智能化: '全角色覆盖酒店管理场景，集中式数据共享，提高管理效率，赋能酒店数字化和智能化',
    多种开锁方式灵活组合: '  多种开锁方式 I 灵活组合',
    开锁方式p1: '支持二维码，卡，蓝牙，密码，给宾客提供极为方便的入住体验',
    开锁方式p2: '既可以使用传统的卡片方式，也可以用系统独特的二维码方式，',
    开锁方式p3: '宾客不用下载App，也可以一码通行',
    二维码介绍: '识别速度快，安全性、多管理角色支持，降低人工成本，让管理更高效',
    二维码: '二维码',
    密码: '密码',
    密码介绍: '密码门锁通常易于使用，不需要任何特殊技能或培训',
    卡: '卡',
    卡介绍: '安全，易用，兼容传统用户习惯',
    蓝牙: '蓝牙',
    蓝牙介绍: '设备功耗更低，链接稳定性更好，降低企业运营成本',
    部署灵活联网离线都支持: ' 部署灵活 I 联网离线都支持',
    离线支持p1: "设备安装简易，支持联网部署，远程一键配置",
    离线支持p2: "也支持无网络部署，用手机App通过二维码或蓝牙配置设备",
    随时随地管理: '随时随地管理',
    随时随地管理p1: '管理者可以随时随地通过PC和手机，查看酒店运营情况，',
    随时随地管理p2: '房态和设备状态，时间和告警，客户请求和服务结果，一切尽在掌握',
    自动化时钟校准: '自动化时钟校准',
    自动化时钟校准p1: '服务器通过自动周期执行，告警触发执行等方式来同步锁的时钟',
    自动化时钟校准p2: '员工也可以通过日常活动，如App蓝牙和二维码开锁等方式，自动校准锁时钟',
    联系电话: '联系电话',
    联系邮箱: '联系邮箱',
    JoyInn酒店公众号: 'JoyInn酒店公众号',
    客服微信号: '客服微信号',
    安装指南: '安装指南',
    快速开店: '快速开店',
    日常操作: '日常操作',
    运营管理: '运营管理',
    Joylnn酒店帮助中心:'Joylnn酒店帮助中心',
    JoyInn酒店系统:'JoyInn酒店系统',

    // 安装指南
    软件系统: '软件系统',
    软件系统h1: '1.酒上使用吗店软件可以在苹果电脑？',
    软件系统p1: '目前电脑端只支持 Windows 系统。',
    软件系统h2: '2.系统安装步骤',

    //快速开店
    入住办理指南: '入住办理指南',
    快速开店h1: '房态管理',
    快速开店p1: `
    <ul style="list-style-position: inside;
    padding-left: 0; ">
     <li>白色代表房间空着；</li>
     <li>蓝色的右上角有小人标的代表已经入住；</li>
     <li>紫色的右上角扫帚标代表需要清洁；</li>
     <li>右上角有时钟标的代表预订；</li>
     <li>右上角绿色地图标的代表预离；</li>
     <li>右上角有红色电池标代表该房间门锁电量低；</li>
     <li>右上角蓝色扳手标代表维修房；</li>
     <li>右上角红色感叹标该房间空房清卡异常；</li>
     <li>右上角红色卡标该房间空空房未清卡；</li>
    </ul>`,
    快速开店p12: '在工作台的右侧有「客房状态查询」，可以帮助我们快速了解房态及根据房态进行筛选。',
    快速开店h2: '办理入住',
    快速开店p21: '在工作台选择相应房间右键选择办理入住，即可为入住人办理入住。开锁支持三种方式：房卡、密码、蓝牙钥匙。',
    快速开店h3: '房卡',
    快速开店p31: '授权前请先确认发卡器连接正常。如发卡器连接失败，请参考 常见问题->设备 中的问题「发卡器连接失败是什么原因」。',
    快速开店p32: '当一个房间入住多个人时，可修改发卡数量。点击界面上确定，将房卡放置在发卡器上听到滴的一声再移开卡片，避免房卡发送失败。',
    快速开店h4: '续住',
    快速开店p41: '在工作台选择相应房间，右键从菜单选项中选择「续房」，设置新离店时间即可。',
    快速开店p42: `
    <ul style="list-style-position: inside;
    padding-left: 0; ">
     <li>「房卡」则需放置在房卡器上进行设置。</li>
     <li>「蓝牙钥匙」将自动延长有效期。</li>
     <li>「密码」若有网关将自动更新有效期，若无网关则需使用手机 APP 在门锁附近更改，或者发送信的密码。</li>
    </ul>
    `,
    快速开店p43: '也可通过工作台->续卡直接进行续卡，请注意续卡并不会续房。',
    快速开店h5: '退房',
    快速开店p51: '在工作台选择相应房间，右键从菜单选项中选择「退房」即可。',
    快速开店p52: '若是房卡，还可通过工作台->退卡进行退卡，请注意退卡并不会退房。',
    快速开店p53: '若房卡丢失，可不勾选「回收开锁权限」。然后再在授权设备->卡进行卡片挂失。',

    //日常操作
    快速上手: '快速上手',
    日常操作p11: '1.下载安装软件，并创建酒店账号。',
    日常操作p12: '点击获取下载方式',
    日常操作p13: '（链接到下载中心）',
    日常操作p2: '2.电脑端登录之后进入酒店即可添加。按照上方左边从到右的红框依次是添加楼栋、楼层、房间',
    日常操作p3: '3.设置房型和房价，可批量修改房间房型及房价，PC端，酒店→楼栋管理→客房管理均可修改，酒店→客房管理，批量修改',
    日常操作p4: '4.为宾客办理入住，在PC端工作台，选择相应房间右键选择【授权】录入入住人信息，选择开锁方式，即可完成办理入住。',

    //运营管理
    设备管理: '设备管理',
    运营管理h1: '房卡',
    运营管理p11: '可以用来开房间、梯控、取电开关，通常搭配发卡器使用。',
    运营管理p12: '房卡中设有 16 个扇区，JoyInn酒店系统默认使用了 1-10 扇区，剩余扇区可用作其它用途。。',
    运营管理p13: '在给员工发开门权限时支持 3 种授权方式：总卡、楼栋卡、楼层卡、梯控卡、自定义。',
    运营管理h2: '房卡',
    运营管理p21: '主要用来发放房卡，将发卡器的 USB 接口接入电脑即可使用。如果发卡器出现故障可检查',
    运营管理p22: '发卡器是否连接，可重新连接一下；',
    运营管理p23: '发卡器驱动是否安装；',
    运营管理p24: '或退出重新登陆，详细请查看该文档 常见问题-设备。',

    //常见问题
    问题101: '「101」发卡器操作异常#',
    文本101: `原因：可能是频繁关闭发卡弹窗导致<br>
    解决方案：退出软件重新打开，请勿关闭发卡弹窗`,
    问题102: '「102」 未发现卡，请重新操作#',
    文本102: `原因：周围是否有设备干扰酒店发卡器的射频信号，例如身份证读卡器<br>
    解决方案：其它设备与发卡器请保持至少 10 公分的距离`,
    问题1002: '「1002」发卡器连接失败#',
    文本1002: `解决方案：请更新软件最新版本后再尝试`,
    问题106: '「106」非本酒店卡#',
    文本106: `请按照以下步骤尝试解决问题：<br>
    1.当前卡片不是第一次使用，在其它软件或酒店账号下使用。请更换未使用过的卡片。<br>
    2.发卡器配套的 3 张工程卡暂时无法使用<br>
    3.请与「身份证读卡器」保持至少 10 公分的距离<br>
    4.未使用过的卡片，请先发卡，再使用「查卡」「续卡」「退卡」操作`,
    问题1003: '「1003」发卡器连接失败，请检查设备是否连接#',
    文本1003: `
    请在确保网络稳定的情况下，按照以下步骤尝试解决问题：<br>
    1.请与「身份证读卡器」保持至少 10 公分的距离<br>
    2.请检查发卡器与端口连接是否松动。<br>
    <ul style="list-style-position: inside;padding-left: 0; ">
     <li>检查图中数字 1数据线是否与发卡器连接有松动，可重新插拔</li>
     <li>检查图中数字 2与电脑 USB 接口是否有松动，可重新插拔或更换电脑 USB 接口</li>
     <li>图中数字 3请换一个数据线试试</li>
    </ul>
    `,
    文本1003p3:`3.请在发卡器连接电脑情况下检查驱动是否安装。以下分别是 win10 和 win7 的查看方式。若不存在图中红色框，请下载驱动，并完全解压到文件夹中
    安装。下载地址。`,
    图1驱动查看位置:'图1 win10 驱动查看位置',
    图2驱动查看位置:'图 2 win7 驱动查看',
    其他建议:`一般情况下电脑是 64 位，安装图中红色框第一个64即可。若无法运行，请尝试图中红色框中第二个x86。
    注：请将驱动压缩包完全解压到文件夹中再进行安装
    若以上都不行，请更换发卡器或电脑尝试。`,

    //升级中文字提示
    正在升级中:'正在升级中',
    正在升级中p1:'尊敬的访客您好<span style="color:#FD8E4D;">JOYINN</span>官网正在升级中',
    正在升级中p2:'请您稍后访问，感谢您对<span style="color:#FD8E4D">JOYINN</span>的支持',
    集团账号介绍:`<p>集团账号是一个总账号，对应于一个酒店集团。集团账号需要在系统里申请，经过审核后，由系统创建。</p>
    <p>集团账号拥有的功能包括:</p>
    <p>1)  可以创建和管理一个或多个酒店账号。</p>
    <p>2)  可以作为第三方服务器账号，通过API接口与Joylnn的云服务接口对接。</p>`,
    账户体系:'账户体系',
    概述:"概述",
    集团账号:"集团账号",
    酒店账号:"酒店账号",
    酒店账号介绍:`<p>酒店账号归属于一个集团账号，对应于集团下面的一个酒店。酒店账号由集团账号登录Joylnn console创建。</p>
              <p>酒店账号拥有管理酒店所有操作功能，具体包括:</p>
              <p>1)  酒店的基本信息管理。</p>
              <p>2)  楼栋/楼层/房间/公共门/电梯的管理</p>
              <p>3)  酒店锁设备的管理，包括锁，网关，门禁，取电开关，梯控等。</p>
              <p>4)  员工的管理</p>
              <p>5)  员工权限管理</p>
              <p>6)  宾客入住管理</p>
              <p>7)  酒店任务管理</p>`,
    员工账号:'员工账号',
    员工账号介绍:`<p>员工账号归属于一个酒店账号，由酒店账号登录前台软件创建。创建员工账号时，需要指定员工对应的角色，</p>
              <p>不同的角色拥有不同的权限。不同的员工权限，体现在可以使用的软件，以及在软件使用过程中可以允许的操</p>
              <p>作。详细信息参照“快速学习-员工管理”</p>`,
    系统框架:'系统框架',
    软件系统:'软件系统',
    软件系统及功能:'软件系统及功能',
    软件系统及功能介绍:`<p style="padding-bottom: 20px;">Web后台管理(Web Console)</p>
              <p>给集团账户和酒店账户提供随时随地的在线管理功能，不需要安装即可使用。</p>
              <p style="padding-bottom: 20px;">集团账户和酒店账户的拥有者，可以通过访问:https://console.joyinn.net登录web console</p>
              <p style="padding-bottom: 20px;">酒店前台软件(Frontdesk Software)</p>
              <p>酒店账户拥有者和有相应权限的员工账户拥有者，可以通过前台执行酒店日常管理功能，包括工作台房态管</p>
              <p>理，宾客的入住管理，酒店的内部实体及设备管理，员工账户及权限的管理，酒店内部任务管理等。</p>
              <p style="padding-bottom: 20px;">酒店前台软件需要从http://www.joyinn.net上下载安装。</p>
              <p style="padding-bottom: 20px;">手机应用(Joylnn Apps)</p>
              <p>手机应用包括管理端(Joylnn Admin App)和宾客端(Joylnn GuestApp)。酒店账户拥有者和有相应权限</p>
              <p>的员工账户拥有者，可以通过Joylnn Admin App，在手机端可以获取权限并操作管理设备，执行内部任务</p>
              <p>等。酒店宾客可以通过宾客端Joylnn Guest App，获取开锁权限和访问酒店服务。手机应用可以从应用商店</p>
              <p style="padding-bottom: 20px;">里下载。</p>
              <p style="padding-bottom: 20px;">卡劵核销软件(Voucher Check Software)</p>
              <p>卡券核销软件，用于给宾客核销相应的服务卡券，如早餐券等。酒店有卡券核销权限的员工，可以使用卡券核</p>
              <p style="padding-bottom: 40px;">销软件，为宾客进行内部卡券的核对和扣减。卡券核销软件需要从http://www.joyinn.net上下载安装。</p>`,
    下载及安装:'下载及安装',
    下载及安装介绍:`<p>1)  连接地址:http://www.joyinn.net/#/home/download</p>
              <p>2)  PC软件的安装需要先安装读卡器驱动</p>
              <p>3)  下载安装前台软件(PC FrontDesk)</p>
              <p style="padding-bottom: 20px;">4)  下载安装卡券核销软件</p>
              <p style="padding-bottom: 20px;">手机App安装可以通过下面两种方式之一:</p>
              <p>1)  扫描网页上的二维码，跳转到Apple Appstore或者Google Play或者其他应用商店安装</p>
              <p>2)  在应用商店里搜索Joylnn Admin或者Joylnn Guest，进行安装。</p>`,
    硬件设备:'硬件设备',   
    设备总览:'设备总览',   
    '系统支持的硬件设备包括:':'系统支持的硬件设备包括:',
    '门锁,门禁,梯控,取点开关,网关,发卡器,二维码扫描器。':'门锁,门禁,梯控,取点开关,网关,发卡器,二维码扫描器',
    门锁:'门锁',  
    门锁主要参数规格:'门锁主要参数规格',
    门禁:'门禁',
    门禁主要参数规格:'门禁主要参数规格',
    梯控:'梯控',
    梯控介绍:`<p>梯控设备由门禁和外部继电器阵列组成，继电器阵列可以支持8，16，32 ports</p>`,
    网关:'网关',
    网关设备主要参数规格:'网关设备主要参数规格',
    取电开关:'取电开关',
    取电开关主要参数规格:'取电开关主要参数规格',
    设备通用操作:'设备通用操作',
    设备通用操作介绍:`<p>门锁/门禁/梯控/网关设备的激活(App截图)</p>
              <p>网关的Wifi配置(APP截图)</p>
              <p>取电开关的新增(App截图)</p>
              <p>门锁/门禁/梯控/网关设备的重置(App截图)</p>
              <p>取电开关的删除(截图)</p>`,
    快速学习:'快速学习',
    创建酒店:'创建酒店',
    集团账号申请:'集团账号申请',
    集团账号申请介绍:`<p>在官网上点击登录，在登录弹框里，→选择"注册"，按要求输入信息，→点击"提交"。</p>
              <p>
                <span style="color: red;">*</span>
                <span style="color: #a7a7a5;">等待工作人员的处理，在账户申请审核通过，集团账户将会被创建，并邮件通知详细的账号登录信息。</span>
              </p>`,
    创建门店账号:'创建门店账号',
    创建门店账号介绍:`<p>在集团账号创建后，在官网上用集团账号登录console</p>
              <p>a)  完善集团的基本信息</p>
              <p>b)  创建集团的酒店账号</p>
              <p>c)  集团制卡操作</p>`,
    创建酒店实体:'创建酒店实体',
    用酒店账号登录PC端前台软件:'用酒店账号登录PC端前台软件',
    完善酒店的基本信息:'完善酒店的基本信息',
    创建楼栋:'创建楼栋',
    创建楼层:'创建楼层',
    创建房型:'创建房型',
    创建房间:'创建房间',
    创建公共门:'创建公共门',
    创建电梯区域:'创建电梯区域',
    绑定设备:'绑定设备',
    绑定设备介绍:`<p>方法一:用酒店账号登录Admin App</p>
              <p>在房态页面，选择要操作的房间</p>
              <p>在房间详情页面，选择新增设备</p>
              <p>选择设备类型，锁或取电开关</p>
              <p>从已有的设备中选择，如没有，可选择新增/激活设备</p>
              <p>在房态页面，选择要操作的公共门/电梯区域</p>
              <p>在选择页面，选择新增设备</p>
              <p>从已有的设备中选择，如没有，可选择新增/激活设备</p>
              <p style="padding-top: 80px;">方法二:用酒店账号登录Admin App</p>
              <p>激活锁/门禁/梯控设备，设定别名(如房间名，便于记忆)</p>
              <p>添加取电开关，设定别名(如房间名，便于记忆)</p>
              <p>在PC端前台软件进行如下操作。</p>
              <p>在房间列表页面逐个房间，绑定锁设备，取电开关</p>
              <p>在公共门列表页面，绑定门禁设备</p>
              <p>在梯控列表页面，绑定梯控设备</p>`,
    '绑定后的设备配置(无关网关场景)':'绑定后的设备配置(无关网关场景)',
    设备配置:'设备配置',
    设备配置介绍:`<p>在联网部署场景下，前面的绑定操作可以自动下发参数配置到门锁，门禁，取电开关，梯控等设备。</p>
              <p>在离线部署场景(没有网关联网的情况)，需要用APP对设备逐个进行配置。</p>
              <p style="padding-top: 20px;">门锁/门禁/梯控的配置</p>
              <p>配置的方法是通过让设备扫描APP生成的配置二维码(App截图)</p>
              <p style="padding-top: 20px;">取电开关的配置</p>
              <p>配置的方法是通过APP进行蓝牙配置操作(App截图)</p>`,
    入住办理:'入住办理',
    房态管理:'房态管理',
    灰色代表着脏房:'灰色代表着脏房',
    绿色代表已入住:'绿色代表已入住',
    橙色代表空房:'橙色代表空房',
    蓝色代表维修房:'蓝色代表维修房',
    右上角多个图标代表同时多个状态:'右上角多个图标代表同时多个状态',
    房态管理介绍:`<p>在工作台右侧[不同房态查询]，快速查看及任务管理栏入口管理</p>`,
    办理入住:'办理入住',
    办理入住介绍:`<p>通过选择楼栋楼层，定位到空闲的目标房间。点击房间图标，在弹出菜单中选择“授权”，进入弹出授权窗。</p>
              <p>录入客人信息，包括姓名，电话(带国家码)，邮箱，输入早餐份数等。</p>
              <p>选择入住时间和预离时间</p>
              <p>选择给客人授权的开锁方式，如二维码，卡，密码，蓝牙等</p>
              <p>选择额外需要授权的公共门和电梯区域</p>
              <p>勾选是否需要给客户发送短信通知(适用于已经开通短信套餐的账户)</p>
              <p>完成上述各项输入后，点击授权按钮</p>`,
    授权房卡:'授权房卡',
    授权房卡介绍:`<p>发卡前，卡片需要做“制卡”操作进行初始化</p>
              <p>发卡前请先确认发卡器链接正常，如发卡器链接失败，情参考“常见为题-设备”中的问题[发卡器链接失败是什么原因]。</p>
              <p>授权成功后，如果开锁方式选择了“卡”方式，提示进入发卡页面。</p>
              <p>发卡时，放置卡片于发卡器上，点击发卡，发卡成功，计数器累加，可以发多张卡</p>
              <p>发卡完成，点击取消，退出发卡页面。</p>`,
    重发授权:'重发授权',
    重发授权介绍:`<p>客人入住期间，如有需要可以重发已有的授权，增发房卡</p>
              <p>快速定位客人授权</p>
              <p>方式一:快捷卡操作，通过读卡器读取客人的房卡，快速进入授权页面</p>
              <p>方式二:通过房态页面，定位到客人房间点击授权菜单进入授权页面</p>`,
    授权变更:'授权变更',
    授权变更介绍:`<p>客人入住期间，可以做授权变更，如续住等原因导致的授权变动。</p>
              <p>方式一:快捷卡操作，通过读卡器读取客人的房卡，快速办理续住</p>
              <p>方式二:通过房态页面，定位到客人房间点击“续住”菜单，办理</p>`,
    卡片挂失:'卡片挂失',
    卡片挂失介绍:`<p>通过快捷卡操作，选择“挂失”，选择房间，办理卡片挂失。</p>
              <p>或者通过房态页面，选择房间，点击菜单中“挂失”，办理卡片挂失。</p>`,
    退房:'退房',
    退房介绍:`<p>方式一:快捷卡操作，通过读卡器读取客人的房卡，快速办理退房</p>
              <p>方式二:通过房态页面，定位到客人房间点击“退房”菜单，办理退房</p>`,
    变更房态:'变更房态',
    变更房态介绍:`<p>房态为“脏房”或“维修”的房间，在确认相应的保洁任务或者维修任务已经完成的情况，可以通过点击房间的</p>
              <p>菜单，人工清除“脏房”或“维修”状态</p>`,
    员工管理:'员工管理',
    '角色（岗位）定义':'角色（岗位）定义',
    '在酒店业务场景中我们存在多个职能，如：':'在酒店业务场景中我们存在多个职能，如：',
    '前台负责办理入住、续房、退房;':'前台负责办理入住、续房、退房;',
    '清洁工负责清洁房问;':'清洁工负责清洁房问;',
    '维修人员，负责检修房间内相关设施，以及酒店区域其他设备，如电梯、网络等相关硬件设备;':'维修人员，负责检修房间内相关设施，以及酒店区域其他设备，如电梯、网络等相关硬件设备;',
    角色介绍:`<p>系统定义了如下角色:</p>
              <p>Admin:  管理员角色，允许登录console，前台，Admin App的管理端，可以管理整个酒店，房态及设备。</p>
              <p>Reception:  前台角色，允许登录前台，可以操作房态，处理与客人入住相关的各项流程。</p>
              <p>HouseKeeper:  保洁员角色，允许登录Admin App的员工端，可以接受并处理保洁任务。</p>
              <p>Maintenance:  维修工角色，允许登录AdminApp的员工端，可以接受并处理维修任务。</p>
              <p>RoomService:  客房服务角色，允许登录Admin App的员工端，可以接受并处理客房服务请求，如:递送客房用品等。</p>
              <p>Voucher Checker:  卡券核销角色，可以登录卡券核销端，核销与客人订单相关的卡券，如早餐券。</p>
              <p style="padding-top: 20px;">酒店注册账号的默认角色是管理员，并拥有所有设备的管理和操作权限。</p>
              <p>除此之外，可以通过console或者前台创建各个角色的员工账号，员工账号和角色的对应关系可以是一对多的关系，</p>
              <p>具体在创建的时候，参照UI的显示进行角色的勾选。</p>`,
    员工授权:'员工授权',
    员工授权介绍:`<p>创建员工账号以后，可以给相应的员工赋予所需要的权限。</p>
              <p>系统为了方便使用，为权限分配提供如下模版:</p>
              <p>总卡(码)，楼栋，楼层，以及自定义组合。</p>
              <p>在员工管理页面，选择员工列，点击打开“更多操作”，选择“授权”，可以进入“授权”弹窗，分配员工授权。</p>
              <p>在“授权”过程中，根据需要可以给员工发实体卡</p>`,
    授权管理:'授权管理',
    '以酒店账号登录console或者登录PC前台，可以在“权限管理”页面，看到所有员工的授权列表。':'以酒店账号登录console或者登录PC前台，可以在“权限管理”页面，看到所有员工的授权列表。',
    '可以通过这个列表修改和删除某个员工的已有授权。':'可以通过这个列表修改和删除某个员工的已有授权。',
    '员工权限使用（房卡功能）':'员工权限使用（房卡功能）',
    员工权限介绍:`<p>员工的权限主要通过Admin App行使,以员工的账户登录Admin App,可以在“房卡”页面,已被授予的权限“房卡”。</p>
              <p>打开该“房卡”,可以看到对应的二维码,该二维码可以打开被权限允许的所有锁设备,包括房间,公共门,电梯等。</p>
              <p>员工除了二维码以外,还可以在房间,公共门,电梯等附近,通过点击房卡页面的蓝牙,看到附近设备相应蓝牙开锁按钮,点击开锁。</p>
              <p>也可以通过点击密码按钮，获取到附近设备的密码，通过密码开锁。</p>`,
    增值服务:'增值服务',
    增值服务及开通:'增值服务及开通',
    '目前系统提供的增值服务包括:':'目前系统提供的增值服务包括:',
    '短信通知服务:用于发送宾客入住通知。':'短信通知服务:用于发送宾客入住通知。',
    '酒店任务管理:保洁，维修，客房服务的任务处理工作流管理。':'酒店任务管理:保洁，维修，客房服务的任务处理工作流管理。',
    '卡券核销:用于酒店宾客卡券的自动核销管理，如早餐券等':'卡券核销:用于酒店宾客卡券的自动核销管理，如早餐券等',
    '增值服务的开通，通过酒店账户登录前台软件或者web console，订购相应的套餐。':'增值服务的开通，通过酒店账户登录前台软件或者web console，订购相应的套餐。',
    酒店任务管理:'酒店任务管理',
    '主要针对保洁、维修、前台等人员的任务管理，可查看任务的详情及结束当前任务状态':'主要针对保洁、维修、前台等人员的任务管理，可查看任务的详情及结束当前任务状态' ,
    '宾客可以通过web页面或者GuestApp发起请求，系统会产生相应的任务。':'宾客可以通过web页面或者GuestApp发起请求，系统会产生相应的任务。',
    '保洁、维修、客房服务等人员，可以通过Admin App查看，接收，处理任务。宾客发起任务界面。':'保洁、维修、客房服务等人员，可以通过Admin App查看，接收，处理任务。宾客发起任务界面。',
    '保洁，维修，客房服务人员接收和处理任务界面。':'保洁，维修，客房服务人员接收和处理任务界面。',
    卡券核销:'卡券核销',
    '入住时，系统可以分配宾客相应的卡券数量，如早餐券等':'入住时，系统可以分配宾客相应的卡券数量，如早餐券等',
    '宾客在相应的场所，能够轻而易举地通过房卡或者二维码进行卡券核销和使用。':'宾客在相应的场所，能够轻而易举地通过房卡或者二维码进行卡券核销和使用。',
    '短信/邮件':'短信/邮件',
    '邮件是必不可少，在注册、登录、接收房间钥匙等都需要通过邮件方式，':'邮件是必不可少，在注册、登录、接收房间钥匙等都需要通过邮件方式，',
    '除了邮件以外，系统提供短信通知服务，不方便使用邮件的宾客，可以通过短信接收入住授权通知。':'除了邮件以外，系统提供短信通知服务，不方便使用邮件的宾客，可以通过短信接收入住授权通知。',
    '短信通知服务，需要酒店预先购买额度。':'短信通知服务，需要酒店预先购买额度。',
    '发送短信，会实时扣减酒店的额度':'发送短信，会实时扣减酒店的额度',
    '酒店可以在前台软件或者web console上，查询发送的历史记录，当前的额度等信息。':'酒店可以在前台软件或者web console上，查询发送的历史记录，当前的额度等信息。',
    数据统计:'数据统计',
    '房间数据统计:观察流量走势，我们可以判断订房的流量高峰期，从而控制房价的价格和库存':'房间数据统计:观察流量走势，我们可以判断订房的流量高峰期，从而控制房价的价格和库存',
    '开门活跃数据:分别蓝牙、房卡、二维码、密码开锁方式，能够精准把握其返穗时间和位置。':'开门活跃数据:分别蓝牙、房卡、二维码、密码开锁方式，能够精准把握其返穗时间和位置。',
    '套餐使用数据:统计出邮件、短信等套餐的已使用、未使用等数据，能够精准了解宾客使用情况。':'套餐使用数据:统计出邮件、短信等套餐的已使用、未使用等数据，能够精准了解宾客使用情况。',
    '房间状态统计:同步统计房价实时状态空房、电量不足、已入住、维修、脏房状态。':'房间状态统计:同步统计房价实时状态空房、电量不足、已入住、维修、脏房状态。',
    '报警数据:为酒店、宾客安全保驾护航':'报警数据:为酒店、宾客安全保驾护航',
    '设备状态统计:设施设备状态关系到酒店安全，服务质量，销售甚至利润。及时关注设施设备正常运行保障酒店宾客人生安全。':'设备状态统计:设施设备状态关系到酒店安全，服务质量，销售甚至利润。及时关注设施设备正常运行保障酒店宾客人生安全。',
    '通过对酒店的入住率、房间利用利率等数据的分析，提高酒店的运营效率。提高营销效果，通过数据中台，可以更好地了解市场需求':'通过对酒店的入住率、房间利用利率等数据的分析，提高酒店的运营效率。提高营销效果，通过数据中台，可以更好地了解市场需求',
    宾客指南:'宾客指南',
    宾客入住通知:'宾客入住通知',
    宾客入住介绍:`<p>宾客在入住完成后,会接收到邮件或者短信通知（取决于酒店的选择）</p>`,
    宾客房卡:'宾客房卡',
    '房卡入口（H5页面）':'房卡入口（H5页面）',
    '宾客点击短信或者邮件里的链接，可以直接打开房卡页':'宾客点击短信或者邮件里的链接，可以直接打开房卡页',
    桌面快捷方式:`<p class="text">保存房卡为桌面快捷方式（手机）</p>
              <p>通过把房卡保存为手机桌面快捷，宾客在入住期间可以方便开机即用</p>
              <p style="color: #D30909;padding-bottom: 10px;">iPone safari浏览器示例</p>
              <p style="color: #D30909;padding-bottom: 30px;">android chrome浏览器示例</p>
              <p class="text">Guest App（手机）</p>
              <p>宾客在房卡页面，点击下载App按钮，跳转到App商店进行下载</p>`,
    '下载安装Guest App后，宾客可以通过入住时登记的邮箱或者手机号登录Guest App，登录时系统会把验证码发到宾客的邮箱作':'下载安装Guest App后，宾客可以通过入住时登记的邮箱或者手机号登录Guest App，登录时系统会把验证码发到宾客的邮箱作',
    为登录鉴权:'为登录鉴权',
    登录截图示例:'登录截图示例',
    'Guest App具有房卡页面的功能':'Guest App具有房卡页面的功能',
    一码通行:'一码通行',
    '宾客使用房卡里的二维码，可以在入住期间，在酒店的授权范围内无障碍通行。':'宾客使用房卡里的二维码，可以在入住期间，在酒店的授权范围内无障碍通行。',
    宾客卡券服务:'宾客卡券服务',
    宾客卡券介绍:`<p class="text">早餐卡券</p>
              <p>宾客在进入早餐厅享用早餐时，可以用二维码或者物理卡的方式进行授权核销</p>
              <p>1）点击打开房卡页面或者打开App的房卡页面，在早餐核销机的QR扫描设备上扫描房卡二维码或者</p>
              <p style="padding-bottom: 40px;">2）在早餐核销机的读卡器上刷房间IC卡。系统会自动扣减相应的早餐卡券。</p>
              <p class="text">其他卡券</p>
              <p>请参照酒店的具体服务信息或者咨询酒店服务人员。</p>  `,
    发卡问题:'发卡问题',
    常见问题:'常见问题',
    '「提示检查发卡器和本地服务」':'「提示检查发卡器和本地服务」',
    提示检查发卡器和本地服务介绍:`<p>解答：如果没有下载安装发卡器驱动，请下载安装。</p>
            <p>如果已经安装，请检查发卡器是否连接正确。电脑未识别到发卡器，请重新插拔插卡器再试一下。或者移除</p>
            <p>其它 USB 设备后再试一下。</p>
            <p>最后请确认发卡器服务有没有正常工作。</p>
            <p>具体确认的方法是：进入如下目录。</p>`,
    点击执行demo:'点击执行demo',
    '在demo页面点击open，如果成功，就表明安装正确，如果失败，需要寻找失败原因并重新安装。':'在demo页面点击open，如果成功，就表明安装正确，如果失败，需要寻找失败原因并重新安装。',
    '需要注意：windows defender或者杀毒软件有可能会误删除发卡驱动。':'需要注意：windows defender或者杀毒软件有可能会误删除发卡驱动。',
    '「发卡的时候提示：请用新卡片重试」':'「发卡的时候提示：请用新卡片重试」',
    '「发出的卡片无法开锁」':'「发出的卡片无法开锁」',
    卡片重试解答:`<p>解答：这种情形通常是空白卡没有预先制卡引起的，请制卡后再操作。混用不同门店的卡片也会引起这个问题。</p>`,
    无法开锁解答:`<p>解答：这种情况通常是锁设备还没有绑定成功，或者是锁设备的时钟不对。详细的信息可以参照智能锁常见问题。</p>`,
    办理入住:'办理入住',
    '「办理入住时，宾客信息如名字，手机号，邮箱等，都是必须填的吗？」':'「办理入住时，宾客信息如名字，手机号，邮箱等，都是必须填的吗？」',
    入住信息解答:`<p>解答：   是的。这些信息都需要填，邮箱是用来接收入住通知以及相应的二维码钥匙。在选择短信通知服务的情况下，手机号是用</p>
            <p>来接收短信通知。邮箱或者手机号还可以作为宾客App的登录账号。</p>`,
    '「办理入住时给宾客发了蓝牙钥匙，宾客如何使用?」':'「办理入住时给宾客发了蓝牙钥匙，宾客如何使用?」',
    蓝牙钥匙解答:`<p>解答：&ensp;&ensp;&ensp;·  第一步：下载下方宾客APP二维码</p>
            <p>&emsp;&emsp;&emsp;&emsp;&ensp;·  第二步：使用登记手机或者邮箱登录，登录时系统将给对应的邮箱或者手机号发送验证码。</p>
            <p>&emsp;&emsp;&emsp;&emsp;&ensp;·  第三步：登录宾客App后，宾客将会看到入住的酒店及房间，点击进入房间。</p>
            <p>&emsp;&emsp;&emsp;&emsp;&ensp;·  第四步：请确认手机蓝牙打开，靠近门锁附近点击开锁</p>`,
    '「提前退房如何删除宾客的开门方式?」':'「提前退房如何删除宾客的开门方式?」',
    提前退房解答:`<p>解答： 对于宾客退房操作，如果是提前退房，系统将会按照如下描述处理授权里包含的开门方式。</p>
            <p>&emsp;&emsp;&emsp;&emsp;&ensp;·  蓝牙钥匙。系统将立即删除该蓝牙钥匙的开锁授权。</p>
            <p>&emsp;&emsp;&emsp;&emsp;&ensp;·  卡片和二维码。如果系统是联网部署的，系统将会即时通过网络删除相关设备上的授权信息。如果离线部署，可以通过两种</p>
            <p>&emsp;&emsp;&emsp;&emsp;&ensp;&ensp;  方式删除该开门方式，一种是给新的宾客发放新卡片和二维码，该宾客如果用卡片或者二维码开锁后，老宾客的卡/二维码自动失效。另</p>
            <p>&emsp;&emsp;&emsp;&emsp;&ensp;&ensp;  一种方式是通过员工在锁上扫描APP上生成的删除码，清除之前宾客的卡和二维码权限。</p>
            <p>&emsp;&emsp;&emsp;&emsp;&ensp;·  密码。如果系统是联网部署的，系统将会即时通过网络删除相关设备上的授权信息。如果是离线部署，通过员工在锁上扫描</p>
            <p>&emsp;&emsp;&emsp;&emsp;&ensp;&ensp;  APP上生成的删除码，清除之前宾客的密码。</p>`,
    '「续住后原来的开门方式还可以使用吗?」':'「续住后原来的开门方式还可以使用吗?」',
    续住后解答:`<p>解答：   续住后，原来的开门方式只能使用到之前的期限，所以需要使用新发钥匙。</p>
            <p>&emsp;&emsp;&emsp;&emsp;&ensp;·  卡：需要重新发卡。</p>
            <p>&emsp;&emsp;&emsp;&emsp;&ensp;·  蓝牙钥匙：在续住后自动更新。</p>
            <p>&emsp;&emsp;&emsp;&emsp;&ensp;·  密码：需要重新发送。</p>
            <p>&emsp;&emsp;&emsp;&emsp;&ensp;·  二维码：需要重新发送。</p>`,
    房卡管理:'房卡管理',
    '「如何查询一张卡片的内容」':'「如何查询一张卡片的内容」',
    查询卡片解答:`<p>答：确认读卡器可以正常工作，打开“工作台”->"卡快捷操作"界面, 可以迅速查询卡片的内容，包括宾客卡和员工卡。</p>`,
    '「如何退卡」':'「如何退卡」',
    退卡解答:`<p>答：拿到卡后，确认读卡器可以正常工作，打开“工作台”->"卡快捷操作"界面, 可以做退卡操作，包括宾客卡和员工卡。如果宾客的卡</p>
            <p>是唯一一张卡，退卡操作将会触发退房操作。如果是员工卡，系统将会删除对应的卡片记录</p>`,
    '「如何续卡」':'「如何续卡」',
    续卡解答:`<p>答：拿到卡后，确认读卡器可以正常工作，打开“工作台”->"卡快捷操作"界面, 可以做续卡操作，包括宾客卡和员工卡。宾客卡的续卡</p>
            <p>将触发续住操作，等价于续住。</p>`,
    '「如何挂失卡」':'「如何挂失卡」',
    挂失卡解答:`<p>答：宾客卡的挂失。打开工作台，点击相应的房间，在弹出菜单中选择"挂失卡"，该房间入住时发放的卡片将全部标记为丢失，对应的授</p>
            <p>权取消。</p>
            <p>员工卡挂失。查找卡片对应的授权记录（可以通过员工名进行查询），在相应的记录上打开操作菜单，选择“卡挂失”。员工挂失卡后，</p>
            <p>可以再通过员工的授权管理重新发新的卡。</p>`,
    '「如何发员工卡（总卡/楼栋/楼层/自定义组合）」':'「如何发员工卡（总卡/楼栋/楼层/自定义组合）」',
    发卡解答:`<p>答：给员工发卡，需要先创建员工账号。创建成功后，可以在“员工管理”页面，选择员工进行授权操作。</p>
            <p>授权里的开锁方式如果包含卡片，在授权创建过程中，会进行发卡操作。</p>`,
    设备:'设备',
    '「JoyInn目前可以支持几种设备」':'「JoyInn目前可以支持几种设备」',
    智能锁:'智能锁',
    门禁:'门禁',
    梯控:'梯控',
    取电开关:'取电开关',
    网关:'网关',
    '可查看开锁记录、关联状态、联网详情、远程开锁、异常记录等':'可查看开锁记录、关联状态、联网详情、远程开锁、异常记录等',
    '「发卡器连接失败是什么原因」':'「发卡器连接失败是什么原因」',
    发卡器连接失败解答:`<p>解答：电脑未识别到发卡器，请重新插拔插卡器再试一下。或者移除其它 USB 设备后再试一下。</p>`,
    '「如何添加锁」':'「如何添加锁」',
    '解答：参见“硬件设备”部分':'解答：参见“硬件设备”部分',
    '「如何删除锁」':'「如何删除锁」',
    '解答：参见“硬件设备”部分':'解答：参见“硬件设备”部分',
    '「卡片/二维码开锁失败，如何判断原因」':'「卡片/二维码开锁失败，如何判断原因」',
    开锁失败解答:`<p>解答：不同的开锁失败原因，会导致不同的LED和蜂鸣表现。</p>
            <p>LED Status:</p>
            <p>1 - Card User number error</p>
            <p>2 - Deregistered number error (card has been deregistered)</p>
            <p>3 - Start date is later than the current date</p>
            <p>4 - Start date is the same as the current date, but start time is later than the current time</p>
            <p>5 - End date is earlier than the current date</p>
            <p>6 - End date is the same as the current date, but end time is earlier than the current time</p>
            <p>7 - No permission</p>
            <p>8 - Not within the specified time period for unlocking</p>
            <p>9 - Door is bolted (no permission to unlock bolted)</p>
            <p>Sound Prompts:</p>
            <p>One beep: Time error (LED 3, 4)</p>
            <p>Three beeps: Data error (LED 1, 2)</p>
            <p>Four beeps: No permission (LED 7)</p>
            <p>Two beeps: Card expired (LED 5, 6, 8)</p>
            <p>No beep: Locked (LED 9)</p>
            <p>Five beeps: Unable to decrypt, or incorrect lockid or regionid   </p>
            <p style="padding-top: 20px;">通过上述表现，可以判断失败原因。另外开锁失败也会上报事件，可以在前台软件中查看失败事件及原因。</p>`,
    '「如何查看锁的本地时钟？」':'「如何查看锁的本地时钟？」',
    查看锁的本地时钟解答:`<p>解答：在联网部署的情况下，在前台软件的“Device”菜单，可以查询到锁的时钟。锁的本地时钟一年之内的跑时误差不超过10分钟，在联网部署的</p>
            <p>情况下，系统会定期同步锁的时钟。</p>
            <p>在离线部署的情况下，酒店员工需要通过配置码每半年或者在更换电池的时候，做一次时钟同步。</p>`,
    系统架构与技术亮点:'系统架构与技术亮点',
    系统架构与技术亮点介绍:`<p>1、物联集成</p>
    <p>深度融合门锁、网关、门禁、梯控、电源开关等设备，基于标准化物联网协议构建统一管控平台。</p>
    <p>低功耗通信：网关采用Sub-1GHz无线技术连接设备，支持Wi-Fi/以太网双模联网，适配多样化酒店网络环境。</p>
    <p>边缘计算架构：云端与本地服务协同，支持断网时PC前台持续运营，保障7×24小时业务不中断。</p>
    <p>设备管理：低成本、易部署的硬件方案，支持远程状态监控、配置同步与时钟校准，确保系统安全稳定。</p>
    <p>2、宾客体验升级</p>
    <p>无接触入住：支持二维码、蓝牙、PIN码、实体卡等多模式授权，无需强制下载App即可完成全流程通行。</p>
    <p>智能服务交互（需宾客App）：</p>
    <p>实时呼叫前台、预约客房服务</p>
    <p>服务进度追踪与状态提醒</p>
    <p>个性化需求提交与反馈</p>
    <p>3、酒店运营赋能</p>
    <p>员工管理：</p>
    <p>多角色权限分级（前台、保洁、维修等）</p>
    <p>移动端任务派发与执行闭环</p>
    <p>效率工具：</p>
    <p>实时房态管理、一键授权、极简入住操作</p>
    <p>自动化工单流转与服务人员实时通知</p>
    <p>成本优化：减少人工沟通耗时，降低运营管理成本。</p>
    <p>4、管理决策支持</p>
    <p>全域可视化：通过Web控制台与管理端App，实时掌握酒店运营全景数据，包括：</p>
    <p>设备状态与告警事件</p>
    <p>客房服务请求与完成率</p>
    <p>能源消耗与设施使用分析</p>
    <p>敏捷部署：缩短现有酒店改造周期，降低新酒店智能化建设成本。</p>`,
    核心价值:'核心价值',
    核心价值介绍:`<p>JoyInn以轻量化架构、高性价比硬件和全流程自动化，助力酒店快速实现智能化升级，为宾客提供无感化优质</p>  
    <p>体验，为管理者打造数据驱动的精益运营体系。</p>`,
    关于我们:'关于我们',
    公司简介:'公司简介',
    简介介绍:`<p>我们专注于物联网在垂直行业的需求和应用，为全球客户</p>
    <p>提供商业场景的IOT软硬件解决方案和服务。智慧酒店一</p>
    <p>站式解决方案及其SAAS服务是我们的核心业务，在这个业</p>
    <p>务领域，我们设计、开发用于电脑，手机，智能锁，网</p>
    <p>关，客房控制等的硬件和软件，并在全球范围提供相应的</p>
    <p>SAAS服务。目前使用我们系统的客户分布于世界各地，我</p>
    <p>们的解决方案和服务有力的支持了他们的商业成功。</p>`,
    联系我们:'联系我们',
    '如有疑问或合作，可以通过以下邮箱联系我们:':'如有疑问或合作，可以通过以下邮箱联系我们:',
    联系邮箱:'联系邮箱',
  },
  智能锁:'智能锁',
};